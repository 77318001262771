.#{$prefix}co-applicant-wrapper {
  gap: 0.25rem;
}

.#{$prefix}co-applicant-success {
  color: $green;
  padding: 0 0 0 1.5rem;
  margin-bottom: 0.75rem;
  position: relative;
  font-size: 14px;
  width: 100%;

  svg {
    content: "";
    background-size: cover;
    width: 14px;
    height: 14px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}
