// TextInput
.#{$prefix}text-input {
  &__root {
    position: relative;
  }

  &__prefix {
    position: absolute;
    z-index: 1;
    left: 0.9rem;
    top: 21px;
    transform: translateY(-50%);
  }

  &__input {
    outline: 0;
    background: #fff;
    border: 1px solid $border;
    position: relative;
    line-height: 1.4;
    appearance: none;
    -webkit-appearance: none;
    width: 100%;
    color: #212121;
    font-size: 16px;
    font-weight: 400;
    font-family: inherit;
    border-radius: 4px;
    padding: 0.5rem 0.8rem;
    box-sizing: border-box;

    &--prefix {
      padding-left: 2.5rem !important;
    }

    &:focus {
      border: 1px solid $main;
    }
  }

  &__validation {
    content: '';
    width: 100%;
    height: 10px;
    position: absolute;
    bottom: 0px;
    border-radius: 0px 0px 4px 4px;
    overflow: hidden;

    &-inner {
      width: 0%;
      height: 2px;
      position: absolute;
      bottom: 0px;
      transition: width 0.4s ease-out;
      width: 0%;

      &.valid {
        background: $valid;
        width: 100%;
      }

      &.invalid {
        background: $invalid;
        width: 100%;
      }
    }
  }
}

// Radio Input
.#{$prefix}radio-input {
  &__root {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 0 1rem;
    // Disable the fieldset border
    border: 0;
    padding: 0;

    &--force-wrap {
      flex-direction: column;

      label {
        margin-top: 0.9rem;
      }
    }
  }

  // This is the first Label / Header of the hole radio button
  &__label-root {
    font-size: 14px;
    font-weight: 500 !important;
    width: 100%;
    display: block;
  }

  &__label {
    position: relative;
    font-size: 16px !important;
    font-weight: 400 !important;
    padding: 0 0 0 1.7rem;
    align-items: center;
    cursor: pointer;
    display: block;
    margin: 0;
  }

  &__input {
    color: #212121;
    font-size: 16px;
    font-weight: 400;
    font-family: inherit;
    line-height: 1.4;
    border: 1px solid #dedbdb;
    cursor: pointer;
    appearance: none;
    padding: 0 !important;
    border-radius: 50% !important;
    bottom: 0;
    left: 0;
    top: 0;
    margin: auto;
    width: 20px !important;
    height: 20px !important;
    position: absolute !important;
    background: #fff;

    transition: border-color 0.4s;

    &.valid.checked {
      border-color: $valid;
    }

    &.invalid {
      border-color: $invalid;
    }
  }

  &__input::after {
    content: '';
    position: absolute;
    margin: auto;
    border-radius: 50%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: $main;
    width: 10px;
    height: 10px;
    opacity: 0;
  }

  &__input:checked::after {
    transition: all 0.4s;
    opacity: 1;
  }
}

// Select
.#{$prefix}select-input {
  &__control {
    border-color: $border;
    border-radius: 4px !important;
    outline: none !important;
    min-height: 40px !important;

    &:hover {
      border-color: $border;
    }
  }

  &__input {
    font-size: 16px;
    font-weight: 400;
  }

  &__control--is-focused {
    box-shadow: none;
    border-color: $main;
    border-radius: 4px !important;

    &:hover,
    &:focus {
      border-color: $main;
      border-radius: 4px !important;
    }
  }

  &__dropdown-indicator {
    position: relative;
    display: block;
    width: 100%;
    border: none !important;
    font-weight: normal;
    padding: 0.5rem 0.8rem;
    padding-right: 0.8rem;
    line-height: 1.4;
    color: inherit;
    background: #fff url('/img/select.svg') calc(100% - 1rem) 50% no-repeat;
    background-size: auto;
    background-size: 7px;
    border: 1px solid $border;
    cursor: pointer;
    outline: none;
  }

  // Hide the default Dropdown indicator
  &__dropdown-indicator > * {
    display: none !important;
  }

  // Position the dropdown exactly under the input
  &__menu {
    margin-top: 1px !important;
    margin-bottom: 0 !important;
    border-radius: 0 !important;
  }

  &__menu-list {
    padding: 0 !important;
  }

  // Selected options background color
  &__option--is-selected {
    background-color: $main !important;
    color: $grey !important;
  }
  // Hover
  &__option--is-focused:not(&__option--is-selected) {
    background-color: $main !important;
    color: $grey !important;
  }

  &__container {
    position: relative;
  }

  &__validation {
    content: '';
    width: 100%;
    height: 10px;
    position: absolute;
    bottom: 0px;
    border-radius: 0px 0px 4px 4px;
    overflow: hidden;

    &-inner {
      width: 0%;
      height: 2px;
      position: absolute;
      bottom: 0px;
      transition: width 0.4s ease-out;
      width: 0%;

      &.valid {
        background: $valid;
        width: 100%;
      }

      &.invalid {
        background: $invalid;
        width: 100%;
      }
    }
  }
}

// Select Native
.#{$prefix}select-input-native {
  &__wrapper {
    position: relative;

    &::after {
      content: ' ';
      position: absolute;
      display: block;
      border: none !important;
      font-weight: normal;
      padding: 0.5rem 0.8rem;
      margin: 0 !important;
      line-height: 1.4;
      color: inherit;
      right: 2px;
      top: 13px;
      background: #fff url('/img/select.svg') calc(100% - 1rem) 50% no-repeat;
      background-size: auto;
      background-size: 7px;
      border: 1px solid $border;
      cursor: pointer;
      outline: none;
      pointer-events: none;
    }
  }

  &__input {
    outline: 0;
    background: #fff;
    border: 1px solid $border;
    position: relative;
    line-height: 1.4;
    appearance: none;
    -webkit-appearance: none;
    width: 100%;
    color: #212121;
    font-size: 16px;
    font-weight: 400;
    font-family: inherit;
    border-radius: 4px;
    padding: 0.5rem 0.8rem;

    &:focus {
      border: 1px solid $main;
    }
  }

  &__validation {
    content: '';
    width: 100%;
    height: 10px;
    position: absolute;
    bottom: 0px;
    border-radius: 0px 0px 4px 4px;
    overflow: hidden;

    &-inner {
      width: 0%;
      height: 2px;
      position: absolute;
      bottom: 0px;
      transition: width 0.4s ease-out;
      width: 0%;

      &.valid {
        background: $valid;
        width: 100%;
      }

      &.invalid {
        background: $invalid;
        width: 100%;
      }
    }
  }
}

// Checkbox
.#{$prefix}checkbox-input {
  &__root {
    padding-left: 2rem;
    font-size: 14px;
    position: relative;
    cursor: pointer;
  }

  &__root-label {
    display: grid !important;
    font-size: 16px;
    font-weight: 400;
    margin-top: 2rem !important;
    cursor: pointer;
    display: grid;

    .#{$prefix}error {
      margin: 0 0 0.5rem 0;
    }
  }

  &__input {
    margin: 0;
    appearance: none;
    cursor: pointer;
    position: absolute;
    left: 0;
    width: 20px;
    padding: 0;
    height: 20px;
    outline: 0;
    border: 1px solid #c4c4c4;
    background: #fff;
    top: 0.15rem;
    color: inherit;
    font-size: 16px;
    font-family: inherit;
    line-height: 1.4;
    border-radius: 4px;

    transition: border-color 0.4s;

    &:focus {
      border: 1px solid $darkgrey;
    }

    &:checked {
      background: #fff url(/img/check.svg) center no-repeat;
      background-size: auto;
      background-size: 17px;
      border-color: $valid;
    }

    &.invalid {
      border-color: $invalid;
    }
  }

  &__label {
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;

    // Fix für A Tags, damit diese Inline angezeigt werden
    a::before {
      display: none;
    }
  }
}

// Tooltip
.#{$prefix}tooltip {
  &__root {
    position: relative;
  }

  &__close {
    width: 13px;
    height: 13px;
    position: absolute;
    cursor: pointer;
    right: 0.7rem;
    top: 0.7rem;
  }

  &__close span {
    position: absolute;
    top: 0.5rem;
    width: 13px;
    height: 2px;
    background: #6f6c6c;
    display: block;
    transform: rotate(45deg);
  }

  &__close span:last-child {
    transform: rotate(-45deg);
  }

  &__text {
    position: relative;
    hyphens: auto;
    display: inline-block !important;
    padding-right: 1.3rem;
  }

  &__icon {
    position: absolute;
    cursor: pointer;
    right: 0;
    top: 0;
    background: $main;
    width: 16px;
    height: 16px;
    display: block;
    font-size: 13px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    font-style: normal;
    border-radius: 50%;
    user-select: none;
  }

  &__content {
    z-index: 5;
    width: max-content;
    max-width: 35vw;
    position: absolute;
    background: #fff;
    font-size: 14px;
    font-weight: 400;
    padding: 0.7rem;
    border: 1px solid $border;
    top: 2.5rem;
    left: 0;
    right: 0;

    @media (max-width: 750px) {
      max-width: 55vw;
    }

    @media (max-width: 900px) {
      max-width: 44vw;
    }

    @media (max-width: 640px) {
      max-width: 44vw;
    }

    p {
      text-align: left !important;

      &:first-of-type {
        margin-top: 0;
        padding-right: 1.5rem;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

// Date input
.#{$prefix}date {
  display: flex;
  flex-flow: row wrap;
  margin-top: 0.9rem;

  &__root {
    padding: 0;
    border: 0;
    margin: 0;

    // Label vom DateInput auf 100% setzen. Ist hier ein <legend> tag
    > .#{$prefix}input-label {
      width: 100%;
    }
  }

  &__wrapper {
    display: grid;
    grid-template-columns: minmax(70px, 1fr) minmax(70px, 40%) minmax(70px, 36%);
    gap: 0.7rem;

    &--no-day {
      grid-template-columns: 1fr 1fr;
    }

    // Fix damit die Selects selbst kein padding bekommen
    > label {
      padding: 0;
    }

    > *,
    .#{$prefix}select-input__input {
      margin: 0;
    }
  }

  @container (max-width: 240px) {
    &__wrapper {
      display: grid;
      grid-template-columns: 100%;
      gap: 0.7rem;
    }
  }
}
