// Birthday
.#{$prefix}birthday {
  display: flex;
  flex-flow: row wrap;
  margin-top: 0.9rem;

  &__root {
    container-type: inline-size;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: minmax(70px, 1fr) minmax(70px, 40%) minmax(70px, 36%);
    gap: 0.7rem;

    > *,
    .#{$prefix}select-input__input {
      margin: 0;
      border-radius: 4px;
    }
  }

  @container (max-width: 240px) {
    &__wrapper {
      display: grid;
      grid-template-columns: 100%;
      gap: 0.7rem;
    }
  }
}

// Nationality, damit es aus der Spalte gehen kann
.#{$prefix}layout--column .#{$prefix}nationality {
  width: calc(200% + 2.5rem);

  @media (max-width: 640px) {
    width: 100%;
  }
}

// LoanSelection
#rootLoanSelection {
  width: calc(100% + 7.5rem);

  @media (max-width: 1250px) {
    width: 188%;
  }

  @media (max-width: 750px) {
    width: 100%;
  }
}

$mobileSize: 800px;
.#{$prefix}loan-selection {
  margin: 4rem 0 0.5rem 0;
  padding: 1.5rem;
  position: relative;
  border-radius: 8px;
  border: 1px solid #e2dfdf;
  background: #f6f6f6;

  &__collapse-btn {
    width: 100%;
  }

  &__arrow {
    position: absolute;
    z-index: 4;

    &--top {
      top: -30px;
      transform: scaleX(-1);
      left: 15px;
    }

    &--bottom {
      bottom: -30px;
      left: 30px;
    }

    img {
      width: 61px !important;
      height: 45px !important;
      display: block !important;
    }
  }

  &__circle {
    background-color: #6ccff1;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    position: absolute;
    top: -80px;
    right: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1f2b43 !important;
    font-size: 18px;
    text-align: center;
    line-height: 20px;

    @media (max-width: 1250px) {
      right: 50px !important; // need to overwrite the inline styling
    }

    @media (max-width: 750px) {
      right: -15px !important; // need to overwrite the inline styling
      top: -50px;
    }

    @media (max-width: 500px) {
      right: 5px !important; // need to overwrite the inline styling
      top: -60px;
      width: 105px;
      height: 105px;
      padding: 0.5rem;
      font-size: 15px;
    }
  }

  &__heading {
    width: 100%;
    @media (max-width: 420px) {
      padding-top: 0.8rem;
    }

    p {
      margin-bottom: 0.5rem;
    }

    h2 {
      margin-top: 0;
      font-weight: 500;
      font-size: 24px;
      @media (max-width: 1500px) {
        max-width: 350px;
      }
      @media (max-width: 1250px) {
        max-width: unset;
      }
      @media (max-width: 1060px) {
        max-width: 50vw;
      }
      @media (max-width: 825px) {
        font-size: 19px;
        max-width: 260px;
      }
      @media (max-width: 750px) {
        margin: 0 0 1rem !important;
      }
      @media (max-width: 425px) {
        font-size: 19px;
        max-width: 260px;
        line-height: 1.35;
        padding-top: 2rem;
        margin-top: 0 !important;
      }
    }
  }

  &__amount {
    width: 200px;

    .text-input__root {
      position: relative;
      input {
        padding-left: 1.4rem;
        background: #F6F6F6;
      }
      .text-input__prefix {
        position: absolute;
        bottom: .55rem;
        left: .5rem;
      }
    }
    &--select {
      width: 50%;
    }

    // Caro möchte hier ein anderen Grau haben
    input {
      color: $mainDark;
      border-radius: 4px;
    }

    @media (max-width: $mobileSize) {
      width: 100%;
    }
  }

  &__usage {
    width: 200px;
  }

  &__body {
    width: 100%;
    min-height: 100px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    gap: 1rem;
    align-items: start;

    @media (max-width: $mobileSize) {
      flex-direction: column;
      align-items: inherit;
    }
  }

  &__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    gap: 1rem;
    margin-top: -1rem;

    @media (max-width: 750px) {
      gap: 0;
      margin-top: 0;
    }

    .loan-form-loan-selection__amount {
      margin: 0;
    }

    & > * {
      flex-grow: 1;
    }

    &__collapsable {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-end;
      width: 100%;
      margin-bottom: 1rem;
      margin-top: -2rem;
      gap: 1rem;
      min-height: 125px;

      .loan-form-input-container {
        margin-bottom: 0;
      }

      & > * {
        flex-grow: 1;
      }

      @media (max-width: 1290px) {
        margin-bottom: 0;
        margin-top: 0;
        .loan-form-input-container {
          label {
          }
        }
      }
    }

    &__button {
      text-align: center;
      width: 200px;
      flex-grow: 1;
      display: flex;
      align-items: center;
      flex-direction: column;

      &--center {
        justify-content: center;
      }


      @media (max-width: $mobileSize) {
        width: 100%;
      }

      @media (max-width: 1290px) {
        &--top {
          margin-top: 0px;
        }
      }
    }
  }

  &__bottom-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__dsgvo {
    @media (max-width: 823px) {
      order: 1;
    }
  }

  &__submit {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-self: flex-end;

    &--noflex {
      display: block;
      width: 100%;
      margin-right: 0;
    }

    @media screen and (max-width: 823px) {
      width: 100%;
    }
  }

  &__submit-btn {
    width: 100%;
  }

  &__runtime-wrapper {
    flex-grow: 1;
    width: 200px;
    container-name: runtime-box;
    container-type: inline-size;

    @media screen and (max-width: 425px) {
      min-width: 100%;
    }
  }

  &__runtime {
    display: grid;
    grid-template-rows: auto;
    gap: 0.4rem;

    &--select {
      grid-template-columns: auto;
    }

    &__box {
      border: 1px solid #dedbdb;
      background: #fff;
      border-radius: 4px;
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 0.3s;
      font-weight: 400;

      &:hover {
        font-weight: bold;
      }

      &--active {
        background: #1f2b43;
        border: 1px solid #1f2b43;
        color: #fff !important;
        font-weight: bold;
      }
    }
  }

  &__monthly-rate {
    min-width: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    gap: 1rem;

    @media screen and (max-width: 823px) {
      justify-content: space-between;
    }

    &__collapsable {
      display: flex;
      gap: 0;
      flex-direction: column;

      @media (max-width: 994px) {
        flex-direction: row;
        align-items: center;
      }
    }

    &__label {
      font-weight: 500;
      font-size: 14px;

      .#{$prefix}tooltip__text {
        hyphens: none !important;
      }
    }

    &__value {
      font-size: 36px;
      font-weight: 500;
      line-height: 43px;
      padding-top: 0.2rem;
    }

    @media (max-width: 994px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      // Damit der Pfeil nicht in den Text ragt
      padding-bottom: 1rem;

      &__label {
        display: inline;
        padding-top: 0.5rem;
        text-align: left;
        flex-shrink: 1;
      }

      &__value {
        font-size: 30px;
        font-weight: 500;
        line-height: 43px;
        text-align: right;
        flex-grow: 1;
        white-space: nowrap;
      }
    }
  }
}


@container runtime-box (max-width: 390px) {
  .#{$prefix}loan-selection__runtime.loan-form-even:not(
      .loan-form-loan-selection__runtime--select
    ) {
    grid-template-columns: repeat(5, 1fr) !important;
  }

  .#{$prefix}loan-selection__runtime.loan-form-odd:not(
      .loan-form-loan-selection__runtime--select
    ) {
    grid-template-columns: repeat(6, 1fr) !important;
  }
}

// FirstStepFinished
.#{$prefix}dsgvo-info {
  display: flex;
  margin-top: 1rem;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-end;
  gap: 1rem;
  min-height: 80px;

  > img {
    margin-top: 0.5rem;
    max-width: 100% !important;
    font-style: italic;
    background-repeat: no-repeat;
    background-size: cover;
    shape-margin: 1rem;
    display: block !important;

    width: 33px !important;
    height: 37px !important;
  }

  &__text {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 0.25rem;

    &__heading {
      margin: 0;
      font-weight: 700;
      font-size: 16px;
      line-height: 23px;
    }

    &__body {
      font-weight: bold;
      font-size: 16px;
      line-height: 18px;
    }
  }
}

.#{$prefix}first-step-finished {
  display: flex !important;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 1rem;

  @media (min-width: $mobileSize) {
    min-height: 80px;
  }
  @media (max-width: $mobileSize) {
    padding-bottom: 1rem;
  }

  > svg {
    content: "";
    margin-top: 0.25rem;
    background-size: cover;
    width: 18px;
    min-width: 18px;
    height: 18px;
  }

  &__text {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 0.25rem;

    &__heading {
      margin: 0;
      font-weight: 700;
      font-size: 16px;
      line-height: 23px;
      color: $green;
    }

    &__body {
      margin: 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: $green;
    }
  }
}

// LoanSlider
.#{$prefix}loan-slider {
  &__container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 1rem;
    justify-content: space-between;
    width: 100%;

    .loan-form-loan-selection__amount {
      width: 220px !important;
    }
  }

  &__subText {
    font-size: 0.82rem;
  }

  &__months {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;

    p {
      flex: 1;

      span {
        font-size: 14px;
        display: block;
      }

      &.right {
        text-align: right;
      }
    }
  }

  &__submit {
    display: flex;
    justify-content: flex-end;
  }
}

.pk-adjustments {
  .loan-form-input-label {
    padding-top: 0.5rem;
  }
}

@media (min-width: 1291px) {
  .pk-adjustments {
    .loan-form-loan-selection__amount {
      width: calc(33.33% - 0.67rem);
    }
  }
}

@media (max-width: 1290px) {
  .loan-form-loan-selection__amount {
    width: calc(33.33% - 0.67rem);
  }
  .loan-form-loan-selection__monthly-rate__collapsable {
    width: 100%;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
  }
}

@media (max-width: 750px) {
  .loan-form-loan-selection__amount {
    width: 100%;
  }
  .loan-form-loan-selection__container__collapsable {
    gap: 0;
  }
  .loan-form-loan-selection__monthly-rate__collapsable {
    margin-top: 1rem;
    margin-bottom: 0.3rem;
  }
}

@media (max-width: 420px) {

  .pk-adjustments .loan-form-loan-selection__monthly-rate__label {
    width: 50px;
    padding-right: 0.5rem;
    padding-top: 0;
    line-height: 1.3;
  }

  .loan-form-tooltip__content {
    max-width: 80vw;
  }
}
