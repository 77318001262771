// LoanSlider
.#{$prefix}loan-slider {
  &__container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 1rem;
    justify-content: space-between;
    width: 100%;

    .loan-form-text-input__input.loan-form-text-input__input--prefix {
      background: $grey;
    }
  }

  &__subText {
    font-size: 0.82rem;
  }

  &__months {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;

    p {
      flex: 1;

      span {
        font-size: 14px;
        display: block;
      }

      &.right {
        text-align: right;
      }
    }
  }

  &__submit {
    display: flex;
    justify-content: flex-end;
  }
}

// Slider
.#{$prefix}slider {
  &__root {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    margin-bottom: 3.4rem;

    &:before {
      content: '1 Jahr';
      position: absolute;
      left: 0;
      top: calc(100% + 1.85rem);
      font-weight: 400;
      font-size: 14px;
    }

    &:after {
      content: '20 Jahre';
      position: absolute;
      right: 0;
      top: calc(100% + 1.85rem);
      font-weight: 400;
      font-size: 14px;
    }
  }

  &__input {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 5px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    opacity: 1;
    padding: 0;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: #0096c8;
      cursor: pointer;
    }

    &::-moz-range-thumb {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: #04aa6d;
      cursor: pointer;
    }
  }

  &__input:focus {
    outline: none;
    border: none;
  }

  &__value {
    font-size: 16px;
    font-weight: 400;
    color: #212121;
  }
}

.range-label {
  position: relative;

  &_data {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    pointer-events: none;
    background: #1F2B43;
    border: none;
    padding: 0.7rem 0.6rem 0.5rem;
    outline: 0;
    color: #fff;
    border-radius: 8px;
    text-align: left;
    max-width: 220px;

    .left-month {
      width: 50%;

      p {
        margin: 0;
      }
    }

    label {
      width: 50%;
    }
  }

  &:before {
    content: '12 Monate';
    position: absolute;
    left: 0;
    top: calc(100% + 1.2rem);
    font-weight: 500;
    font-size: 14px;
  }
  &:after {
    content: '240 Monate';
    position: absolute;
    right: 0;
    top: calc(100% + 1.2rem);
    font-weight: 500;
    font-size: 14px;
  }
  &_triangle {
    position: relative;
    //   margin: auto;
    top: 6px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #1F2B43;
  }
}
