.#{$prefix}dsgvo-callout {
  font-size: 14px;
  margin-bottom: 1rem;

  p {
    margin-top: 0;
  }

  div {
    display: flex;
    margin-top: 1rem;

    p {
      margin: 0;
      padding-left: 0.5rem;
    }
  }

  svg {
    width: 22px;
    height: 22px;
  }
}

.#{$prefix}company-street {
  @include responsive-grid(1fr 30%);
}

.#{$prefix}company-location {
  @include responsive-grid(1fr 60%);
}

.#{$prefix}existing-loan,
.#{$prefix}further-income {
  border: 1px solid $border-dark;
  padding: .2rem 1rem 0;
  margin-top: 1.5rem;
}

.#{$prefix}remove-further-income {
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  display: flex;
  gap: .5rem;
  font-weight: 500;
  font-size: 14px;
  font-family: Roboto,sans-serif;
  color: $mainDark;
  background: 0 0;
  border: none;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  padding: 0;

  svg {
    display: inline-flex;
    height: 20px;
    width: 20px;
  }
}
