.#{$prefix}modal {
  position: fixed;
  justify-content: center;
  align-items: center;
  background: rgba(255,255,255,.9);
  padding: 1rem;
  inset: 0;
  transition: all .5s;
  visibility: visible;
  display: flex;
  z-index: 999;
  opacity: 1;

}

.#{$prefix}modal-form {
  background: $grey;
  box-shadow: 0 6px 20px #0000002b;
  max-width: 1150px;
  text-align: left;
  margin: 1rem auto;
  max-height: 95vh;
  overflow: auto;
  padding: 1rem 7vw 3rem;
  height: auto;
  position: relative;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  color: $mainDark;
  padding: 1rem 4vw 3rem;

  &-result {
    max-width: 750px;
    text-align: center;
    padding: 4rem 4vw 4.5rem;
    flex-direction: column;
  }

  .#{$prefix}half {
    width: calc(50% - 0.5rem);

    @media (max-width: 750px) {
      width: 100% !important;
    }
  }
}

.#{$prefix}modal-close {
  width: 17px;
  height: 17px;
  position: absolute;
  cursor: pointer;
  right: 1.3rem;
  top: 1.3rem;

  @media (max-width: 940px) {
    position: fixed;
    z-index: 2;
    top: 2.7rem;
    right: 2.5rem;
  }

  span {
    position: absolute;
    top: .5rem;
    width: 17px;
    height: 2px;
    background: #6f6c6c;
    display: block;
    transform: rotate(45deg);

    &:last-child {
      transform: rotate(-45deg) !important;
    }
  }
}

.#{$prefix}modal-heading {
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 0;
}

.#{$prefix}modal-duty {
  font-size: 14px;
  font-weight: 500;
  max-width: none;
  width: 100%;
  color: $mainDark;
  align-self: center;
  margin: 1rem 0 1.33rem auto;
  text-align: right;

  @media (max-width: 940px) {
    top: 1.7rem;
    right: 4rem;
    z-index: 1;
    display: inline-block;
    width: auto;
    background: $grey;
    padding: 0 .3rem;
    position: fixed;
  }
}

.#{$prefix}modal-flex {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  gap: 0.25rem 1rem;
}

.#{$prefix}modal-textarea {
  width: 100%;
  background: #fff;
  border: 1px solid $border;
  min-height: 230px;
  font-family: inherit;
  font-size: inherit;
  color: $mainDark;
  padding: .5rem;
  outline: none;
  resize: none;

  &:focus {
    border: 1px solid $main;
  }
}

.#{$prefix}modal-privacy {
  position: relative;
  text-align: left;
  width: 100%;
}

.#{$prefix}modal-submit-wrapper {
  margin: 1rem 0 0 auto;
  position: relative;
}
